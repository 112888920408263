export default defineI18nConfig(() => ({
    fallbackLocale: 'de',
    datetimeFormats: {
        'de': {
            dateShort: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            dateTime: {
                year: 'numeric', month: 'short', day: 'numeric',
                hour: 'numeric', minute: 'numeric'
            },
            dateTimeLong: {
                year: 'numeric', month: 'short', day: 'numeric',
                hour: 'numeric', minute: 'numeric', second: 'numeric'
            },
            timeShort: {
                hour: 'numeric', minute: 'numeric'
            }
        }
    }
}))